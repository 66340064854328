import { Button, useMatches } from "@mantine/core";
import {
  IconArrowRight,
  IconMail,
  IconMapPin2,
  IconPhone,
  IconTopologyStar3,
} from "@tabler/icons-react";
import { useState } from "react";
import FloatingInput from "./FloatingInput";
import { validateForm } from "./Validation";
import {  setDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import toast from "react-hot-toast";
const Contact = () => {
  const phoneNumber1 = "+919140196641";
  const phoneNumber2 = "+917558726131";
  const emailAddress = "technosolz01@gmail.com"
  const address = 'A/502 Sunshine park, Mumbai, Maharashtra 401209';
  const form = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };
  const [formData, setFormData] =useState<{ [key: string]: string }>(form);
  const [formError, setFormError] = useState<{ [key: string]: string }>(form);
  const handleChange = (id: string, value: string) => {
    setFormData({ ...formData, [id]: value });
    setFormError({ ...formError, [id]: validateForm(id, value) });
  };
  const handleSubmit = async () => {
    let valid = true;
    let newFormError : {[key:string]:string}={};
    for (let key in formData) {
      const error = validateForm(key, formData[key]);
      if (error.length > 0) {
        newFormError[key] = error;
        valid = false;
      }
    }
    setFormError(newFormError);
    if (valid) {
      setFormData(form);
      await setDoc(doc(db, "users", formData.name), formData);
      toast.success("Submitted successfully!",{duration:4000});
    }else{
      toast.error("Please fill the form correctly!",{duration:4000});
    }
  }
  const btn = useMatches({
    xs: "xs",
    sm: "sm",
    md: "md",
    lg: "lg",
  });
  return (
    <div className="px-16 my-10 font-mono md-mx:px-6 " id="Contact">
    <h1 className="text-4xl font-bold sm-mx:text-3xl xs-mx:text-2xl text-center mb-5 text-black">
Contact Us
    </h1>
    <div data-aos="zoom-out-up"
    data-aos-duration="800"
    className="flex relative overflow-hidden justify-around items-center font-mono px-10 py-10 sm-mx:px-4 xs-mx:px-2 xs-mx:py-4 h-fit lg-mx:justify-between bs-mx:flex-wrap bs-mx:flex-col-reverse bs-mx:!overflow-visible bs-mx:gap-2 md-mx:px-2"
    id="bg">
      <div className=" sm-mx:ml-0 xs-mx:ml-0 xsm-mx:ml-0 w-[90%] flex flex-col lg-mx:gap-3 bs-mx:items-center xs-mx:hidden sm-mx:hidden xsm-mx:hidden ">
        <div
          data-aos="flip-left"
          data-aos-duration="800"
          className="w-[90%] lg-mx:w-full shadow-[0_0_10px_0_#64FFDA50] m-auto flex flex-col gap-2 border border-primaryColor  p-4 sm-mx:p-4"
        >
          <div className="flex border-b-2 border-dashed border-white p-4">
            <div className="w-[15%] p-2">
              <IconPhone
                className="h-10 w-10 sm-mx:h-7 sm-mx:w-7"
                color="#64FFDA"
                stroke={1.25}
              />
            </div>
            <div className="w-[70%] p-2">
              <h1 className="text-3xl text-black ">Call us on</h1>
              <div className="text-xl text-black ">
                <a href={`tel:${phoneNumber1}`}>{phoneNumber1}</a>
              </div>
              <div className="text-xl text-black ">
                <a href={`tel:${phoneNumber2}`}>{phoneNumber2}</a>
              </div>
            </div>
          </div>


          {/* mailus */}
          <div className="flex border-b-2 border-dashed border-white p-4">
            <div className="w-[15%] p-2">
              <IconMail
                className="h-10 w-10 sm-mx:h-7 sm-mx:w-7"
                color="#64FFDA"
                stroke={1.25}
              />
            </div>
            <div className="w-[70%] p-2">
              <h1 className="text-3xl text-black ">Mail us</h1>
              <div className="text-xl text-black ">
              <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
              </div>
              
            </div>
          </div>
          {/* address */}
          <div className="flex p-4">
            <div className="w-[15%] p-2">
              <IconMapPin2
                className="h-10 w-10 sm-mx:h-7 sm-mx:w-7"
                color="#64FFDA"
                stroke={1.25}
              />
            </div>
            <div className="w-[70%] p-2">
              <h1 className="text-3xl text-black ">Address</h1>
              <div className="text-xl text-black ">
              {address}
              </div>
              
            </div>
          </div>

        </div>
      </div>
      <div className="h-fit flex justify-center items-center  rounded-full  w-[90%]">
        <div
          data-aos="flip-left"
          data-aos-duration="800"
          className="w-[90%] lg-mx:w-full shadow-[0_0_10px_0_#64FFDA50] m-auto flex flex-col gap-3 border border-primaryColor  p-4 sm-mx:p-4"
        >
          <div className=" text-3xl text-black font-semibold sm-mx:text-2xl xs-mx:text-xl">
            Let's Contact
            <IconTopologyStar3 className="text-primaryColor h-10 w-10 sm-mx:h-7 sm-mx:w-7" />{" "}
          </div>
          <FloatingInput id="name" name="Name" value={formData.name} handleChange={handleChange} error={formError.name} />
          <FloatingInput id="email" name="Email" value={formData.email} handleChange={handleChange} error={formError.email} />
          <FloatingInput id="phone" name="Phone" value={formData.phone} handleChange={handleChange} error={formError.phone} />
          <FloatingInput id="message" name="Message" value={formData.message} handleChange={handleChange} error={formError.message} />
          <Button
            className="!text-bgColor font-bold "
            fullWidth
            variant="filled"
            size={btn}
            onClick={handleSubmit}
            radius="lg"
            color="#64FFDA"
            rightSection={
              <IconArrowRight className="h-10 w-10 sm-mx:h-7 sm-mx:w-7" />
            }
          >
            Send
          </Button>
        </div>
      </div>
    </div>
    </div>
    
    
    
  );
};
export default Contact;

// <div  className="w-[47%] px-16 mx-20 md-mx:px-8 sm-mx:pc-4 lg-mx:mx-12 md-mx:mx-0 my-10 mb-28 font-mono p-5 bs-mx:p-3 sm-mx:w-full"
// id="Contact">

//     <div
//       data-aos="flip-left"
//       data-aos-duration="800"
//       className="w-[50%] lg-mx:w-full shadow-[0_0_10px_0_#64FFDA50] m-auto flex flex-col gap-6 border border-primaryColor rounded-3xl p-8 sm-mx:p-4"
//     >
//       <div className=" text-3xl text-black font-semibold sm-mx:text-2xl xs-mx:text-xl">
//         Let's Contact
//         <IconTopologyStar3 className="text-primaryColor h-10 w-10 sm-mx:h-7 sm-mx:w-7" />{" "}
//       </div>
//       <FloatingInput id="name" name="Name" value={formData.name} />
//       <FloatingInput id="email" name="Email" value={formData.email} />
//       <FloatingInput id="phone" name="Phone" value={formData.phone} />
//       <FloatingInput id="message" name="Message" value={formData.message} />
//       <Button
//         className="!text-bgColor font-bold "
//         fullWidth
//         variant="filled"
//         size={btn}
//         radius="lg"
//         color="#64FFDA"
//         rightSection={
//           <IconArrowRight className="h-10 w-10 sm-mx:h-7 sm-mx:w-7" />
//         }
//       >
//         Send
//       </Button>
//     </div>
//   </div>