
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { useState } from "react";
import { AllSolutionType } from "../User";

const SolutionType = () => {
  const [solution, setSolution] = useState(AllSolutionType);

  const cardFun = (mess: string, im: string) => {
    return (
      <Card
        sx={{
          maxWidth: 300,
          height: 250,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "25px",
          marginBottom: "10px", // Small margin between cards
        }}
      >
        <CardMedia
          className="
            w-full 
            xsm:w-[60%] 
            xsm:h-[44%] 
            sm:w-[50%] 
            md:w-[50%] 
            lg:w-[50%] 
            lg:h-[67%] 
            xl:w-[50%] 
            xl:h-[67%] 
            p-2 
            xl:p-4 
            lg:p-5 
            sm:p-3 
            md:p-4 
            object-cover
            object-top
          "
          image={`/images/${im}`}
          title={mess}
          sx={{
            paddingTop: "10px", // Add space above the image to prevent it from being cut off
          }}
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            paddingBottom: 2, // Reduced padding at the bottom of card content
          }}
        >
          <Typography
            gutterBottom={false} // Removed gutterBottom to reduce space between title and content
            variant="h6"
            component="div"
            sx={{
              fontSize: {
                xs: ".90rem", // Font size for extra small screens
                sm: ".95rem", // Font size for small screens
                md: "1rem", // Font size for medium screens
                lg: "1.2rem", // Font size for large screens
              },
              lineHeight: 1, // Slightly increase line height for readability
              fontWeight: 200, // Make the font lighter
            }}
          >
            {mess}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <div className="px-16 sm-mx:px-0 xs-mx:px-0 xsm-mx:px-0 my-10 font-mono md-mx:px-6" id="Services">
      <h1 className="text-4xl font-bold sm-mx:text-3xl xs-mx:text-2xl text-center mb-5 text-black">
       Services
      </h1>
      <div className="py-10 px-8 bg-gray-200 pl-12 sm-mx:pl-0 xs-mx:pl-0 sm-mx:px-0 xs-mx:px-0 xsm-mx:px-0">
      <Box sx={{ flexGrow: 1, margin: "5px" }}>
        <Grid container spacing={2}> {/* Reduced spacing from 4 to 2 */}
          {solution.map((elem) => (
            <Grid item xs={6} sm={3} md={3} lg={3} key={elem.name}>
              {cardFun(elem.name, elem.im)}
            </Grid>
          ))}
        </Grid>
      </Box>
      </div>
    </div>
  );
};

export default SolutionType;
