import { Button, useMatches } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Typewriter from "typewriter-effect";
import { Info } from "../User";
import { NeonGradientCard } from "./magicui/neon-gradient-card";
import { Link } from "react-router-dom";
const About = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const btn = useMatches({
    xs:'xs',
    sm:'sm',
    md:'md',
    lg:'lg'
  })
  return (

<>
      <div
        data-aos="zoom-out-up"
        data-aos-duration="800"
        className="mt-28 flex relative overflow-hidden justify-around items-center font-mono px-5 py-10 sm-mx:px-4 xs-mx:px-2 xs-mx:py-4 h-fit lg-mx:justify-between bs-mx:flex-wrap bs-mx:flex-col-reverse bs-mx:!overflow-visible bs-mx:gap-6 md-mx:px-6"
        id="bg"
      >
        
        <div className="ml-10 sm-mx:ml-0 xs-mx:ml-0 xsm-mx:ml-0 w-1/2 xsm-mx:w-full xs-mx:w-full sm-mx:w-full flex flex-col lg-mx:gap-2 bs-mx:items-center ">
          <div className="text-primaryColor text-3xl lg-mx:text-2xl xs-mx:text-3xl xsm-mx:text-[30px]">
          Welcome to
          </div>
          <div className="text-black text-[4.25rem] font-extrabold lg-mx:text-5xl sm-mx:text-4xl xs-mx:text-2xl xsm-mx:text-[26px]">
            {Info.name}
          </div>
          <div className="text-black text-3xl flex font-semibold lg-mx:text-[24px] sm-mx:text-lg xs-mx:text-sm xsm-mx:text-sm">
          Trusted provider of&nbsp;
            <span className="text-primaryColor">
              <Typewriter
                options={{
                  strings: Info.stack,
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>{" "}
          </div>
          <div className="text-textColor text-xl w-[90%] text-justify my-8 lg-mx:my-0 font-semibold lg-mx:text-[24px] sm-mx:text-lg xs-mx:text-sm xsm-mx:text-sm">
            {Info.bio}
          </div>
          <div className="flex gap-3">
            <Button
              component={Link}
              to="/contact"
              className="!text-bgColor !w-fit"
              size={btn}
              variant="filled"
              color="#64FFDA"
            >
              Contact
            </Button>
           
          </div>
        </div>

        <div
          className="h-fit flex justify-center items-center  rounded-full  w-fit"
          id="photo"
        >
          <NeonGradientCard className="w-[600px] h-[600px] lg-mx:w-64 lg-mx:h-64 xsm-mx:w-56 xsm-mx:h-56 items-center justify-center text-center">
            <img
              src={`${process.env.PUBLIC_URL}/Icons/Designer.jpeg`}
              className="w-full h-full rounded-full "
              alt="profile"
            />
          </NeonGradientCard>
        </div>
      </div>
    </>
  );
};

export default About;