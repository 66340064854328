const validateForm = (id: string, value: string) => {
  switch (id) {
    case "name":
      if (value.length === 0) return "Name is required";
      if (value.length < 3) return "Name must be at least 3 characters";
      return "";
    case "email":
      if (value.length === 0) return "Email is required";
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value))
        return "Invalid email address";
      return "";

    case "phone":
      if (value.length === 0) return "Phone is required";
      if (value.length < 10) return "Phone must be at least 10 characters";
      if (!/^\d+$/.test(value)) return "Phone must be a number";
      if (value.length > 15) return "Phone must be 15 digits";
      return "";
      
    case "message":
      if (value.length === 0) return "Message is required";
      if (value.length < 10) return "Message must be at least 10 characters";
      return "";
    default:
      return "";
  }
};
export { validateForm };
