
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyDldF_XOR6r_W-Qm6vA6EAi3F-NXroO6ho",
  authDomain: "technosolz-76590.firebaseapp.com",
  projectId: "technosolz-76590",
  storageBucket: "technosolz-76590.firebasestorage.app",
  messagingSenderId: "781951737678",
  appId: "1:781951737678:web:5c121c4ad49efd13e4081f",
  measurementId: "G-4SS60TY8KS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);